import React from "react";
import {
  HeroContainer,
  HeaderContainer,
  HeaderText,
  SubText,
  ImgContainer,
  Img,
} from "./styles";
import Food from "../../../assets/imgs/aboutImgThree.png";

const HeroTwo = () => {
  return (
    <HeroContainer>
      <ImgContainer>
        <Img src={Food} />
      </ImgContainer>
      <HeaderContainer>
        <HeaderText>Wie joinpuya geboren wurde</HeaderText>
        <SubText>
          Anfang 2024 habe letztlich eine wichtige Entscheidung für mein Leben
          getroffen: Ich habe meinen Job gekündigt und direkt meine eigene
          Bodytransformation gestartet. Sport ist mein Leben - daher gründete
          ich meine eigene Firma unter dem Namen Joinpuya. Ich arbeite sehr gern
          mit Menschen - egal in welchen Alters. Ich möchte mein Wissen und
          meine Leidenschaft für den Sport weitergeben und Menschen in ihren
          individuellen Lebenssituationen begleiten um ihre Ziele zu erreichen.
        </SubText>
      </HeaderContainer>
    </HeroContainer>
  );
};

export default HeroTwo;
