import React from "react";
import { MainContainer, HeaderWrapper, HeaderText, SubText } from "./styles";

const Sub = () => {
  return (
    <MainContainer>
      <HeaderWrapper>
        <HeaderText>Mit den richtigen Trainingsplan zu deinem besten Ich!</HeaderText>
        <SubText>
          Möchtest du überschüssiges Gewicht verlieren, Muskeln aufbauen oder
          einfach fitter und gesünder werden? Mit meinen individuellen
          Trainingsplänen bekommst du genau das, was du brauchst, um deine Ziele
          zu erreichen – und das effizient und nachhaltig. Jedes Programm wird
          speziell auf deine Bedürfnisse und deine Lebensumstände angepasst,
          damit du schnell Fortschritte bemerkst und motiviert bleibst. Meine
          Pläne sind nicht nur effektiv, sondern basieren auf fundierten
          Methoden, die für langfristige und nachhaltige Ergebnisse sorgen. Mit
          meiner Unterstützung holst du das Beste aus dir heraus und bringst
          deinen Körper auf das nächste Level. Jetzt ist der perfekte Zeitpunkt,
          um zu starten. Schau dir meine Traingsplan-Angebote an und beginne noch heute
          deine Transformation!
        </SubText>
      </HeaderWrapper>
    </MainContainer>
  );
};

export default Sub;
