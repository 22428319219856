import React from "react";
// components //
import {
  PricingPlans,
  BasicPlan,
  StandardPlan,
  PremiumPlan,
  Heading,
  Price,
  Features,
  CtaButton,
} from "./styles";

const Offers = () => {
  return (
    <PricingPlans>
      <BasicPlan>
        <Heading>
          <h4>Ernährungsplan zum Abnehmen</h4>
          <p>1 Monat</p>
        </Heading>
        <Price>80€</Price>
        <Features>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 4 individuelle Wochenpläne</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Körperanalyse</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 4x 15 min Videocall-Update</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 30 min kostenlose Beratung</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Unterstützung via E-Mail und WhatsApp</strong>
          </li>
        </Features>
        <CtaButton href="https://buy.stripe.com/4gw4iXfet8QDcGQ00e">
          Kaufen
        </CtaButton>
      </BasicPlan>
      <StandardPlan>
        <Heading>
          <h4>Ernährungsplan zum Zunehmen </h4>
          <p>1 Monat</p>
        </Heading>
        <Price>70€</Price>
        <Features>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 4 individuelle Wochenpläne</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Körperanalyse </strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 2x 15 min Videocall-Update</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 30 min kostenlose Beratung</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Unterstützung via E-Mail und WhatsApp</strong>
          </li>
        </Features>
        <CtaButton href="https://buy.stripe.com/fZecPt5DTff10Y8fZd">
          Kaufen
        </CtaButton>
      </StandardPlan>
      <PremiumPlan>
        <Heading>
          <h4>Ernährungsplan zum Muskelaufbau</h4>
          <p>1 Monat</p>
        </Heading>
        <Price>80€</Price>
        <Features>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 4 individuelle Wochenpläne</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Körperanalyse </strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 4x 15 min Videocall-Update</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 30 min kostenlose Beratung </strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Unterstützung via E-Mail und WhatsApp</strong>
          </li>
        </Features>
        <CtaButton href="https://buy.stripe.com/9AQdTxc2hgj5fT228o">
          Kaufen
        </CtaButton>
      </PremiumPlan>
    </PricingPlans>
  );
};

export default Offers;
