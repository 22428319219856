import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  padding: 20px 40px;
  z-index: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 960px) {
    display: flex;
    padding: 15px 5px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  @media screen and (max-width: 960px) {
    width: 90%;
    text-align: start;
    padding: 25px 10px;
  }
`;

export const HeaderText = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  flex-direction: column;
  color: #001524;
  opacity: 0.8;
  line-height: 2rem;
  @media screen and (max-width: 960px) {
    font-size: 18px;
    font-weight: 500;
  }
`;

export const HeaderBoxWrapper = styled.div`
  margin-top: 20px;
  width: 85%;
  padding: 10px 10px;
  border-radius: 12px;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset,
    rgba(0, 0, 0, 0.9) 0px 0px 0px 1px;
  @media screen and (max-width: 960px) {
    width: 90%;
    margin-top: 0px;
  }
`;

export const BoxListWrapper = styled.div`
  padding: 0px 20px;
  margin-bottom: 10px;
  @media screen and (max-width: 960px) {
    padding: 0px;
  }
`;

export const SubText = styled.div`
  font-size: 18px;
  font-weight: 400;
  margin-top: 15px;
  align-self: center;
  color: #022c43;
  opacity: 0.8;
  line-height: 1.8rem;
  width: 100%;
  @media screen and (max-width: 960px) {
    font-size: 14px;
    text-align: start;
  }
`;
