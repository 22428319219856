import React from "react";
// Components //
import {
  ProductDisplayContainer,
  HeaderText,
  DisplayContainer,
  DisplayContainerSectionTwo,
  TextContainer,
  DisplayText,
  SubText,
  ImgContainer,
  Img,
  FeaturesConatainer
} from "./styles";
// imgs //
import f1 from "../../../assets/imgs/homeImg.png";
import f2 from "../../../assets/imgs/proSwim.png";
// Data source //
import Home from "../../../assets/Data/Home.json";

const Features = () => {
  return (
    <ProductDisplayContainer>
      <HeaderText>{Home.Featured.HeaderText}</HeaderText>
      <DisplayContainer>
        <TextContainer>
          <DisplayText>{Home.Featured.ContextOne}</DisplayText>
          <SubText>{Home.Featured.SubContentOne}</SubText>
          <FeaturesConatainer>
          <li>
            <SubText>✔️ Bachelor of Sportwissenschaften</SubText>
          </li>
          <li>
            <SubText>✔️ Personal Trainer A-Lizenz</SubText>
          </li>
          <li>
            <SubText>✔️ Zwanzig Jahre Fitness-Erfahrung</SubText>
          </li>
        </FeaturesConatainer>
        </TextContainer>
        <ImgContainer>
          <Img src={f1} alt="imgFeature" />
        </ImgContainer>
      </DisplayContainer>
      {/* Second  */}
      <DisplayContainerSectionTwo>
        <ImgContainer>
          <Img src={f2} alt="imgFeatureTwo" />
        </ImgContainer>
        <TextContainer>
          <DisplayText>{Home.Featured.ContextTwo}</DisplayText>
          <SubText>{Home.Featured.SubContentTwo}</SubText>
          <FeaturesConatainer>
          <li>
            <SubText>✔️ Bachelor of Sportwissenschaften</SubText>
          </li>
          <li>
            <SubText>✔️ Personal Trainer A-Lizenz</SubText>
          </li>
          <li>
            <SubText>✔️ Ehemaligen Nationalschwimmer</SubText>
          </li>
        </FeaturesConatainer>
        </TextContainer>
      </DisplayContainerSectionTwo>
    </ProductDisplayContainer>
  );
};

export default Features;
