import React from "react";
import { MainContainer, HeaderWrapper, HeaderText, SubText } from "./styles";

const Sub = () => {
  return (
    <MainContainer>
      <HeaderWrapper>
        <HeaderText>
          Personaltraining für jeden - individuell und maßgeschneidert
        </HeaderText>
        <SubText>
          Meine privaten Fitnesscoachings sind mehr als nur Training – sie sind
          dein Schlüssel zu nachhaltigem Erfolg. Gemeinsam entwickeln wir ein
          individuelles Training , das dich Schritt für Schritt stärker,
          flexibler und fitter und gesünder macht. Du profitierst von meiner
          Expertise und persönlicher Betreuung, um das Beste aus dir
          herauszuholen. Egal, ob duabnehmen , Muskeln aufbauen oder deine
          Beweglichkeit verbessern möchtest – ich begleite dich mit einem klaren
          Plan und der Motivation, die du brauchst, um deine Ziele zu erreichen:
          ganz individuell. Starte jetzt dein Personaltraining mit mir ! Buche
          deine erste Session und erlebe, was es heißt, dein volles Potenzial zu
          entfalten. Meine Fitnesstrainings eignen sich für erwachsene Männer
          und Frauen, Senior*innen sowie für Anfänger und professionelle
          Sportler*innen gleichermaßen
        </SubText>
      </HeaderWrapper>
    </MainContainer>
  );
};

export default Sub;
