import styled from "styled-components";

export const ProductDisplayContainer = styled.div`
  margin-top: 5%;
  text-align: center;
  @media screen and (max-width: 960px) {
    margin-top: 5%;
    margin-bottom: 10%;
  }
`;

export const HeaderText = styled.span`
  color: #022c43;
  font-size: 38px;
  font-weight: 900;
  margin-bottom: 2%;
  text-align: center;
  @media screen and (max-width: 960px) {
    font-size: 28px;
    font-weight: 900;
    margin-bottom: 10%;
    line-height: 38px;
  }
`;

export const DisplayContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 0 30px;
  height: 700px;
  z-index: 1;
  @media screen and (max-width: 960px) {
    margin-top: 50px;
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const DisplayContainerSectionTwo = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 0 30px;
  height: 700px;
  position: relative;
  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
`;

export const DisplayText = styled.span`
  color: #022c43;
  font-size: 38px;
  font-weight: 900;
  margin-bottom: 18px;
  @media screen and (max-width: 960px) {
    font-size: 28px;
    font-weight: 900;
  }
`;

export const SubText = styled.span`
  color: #022c43;
  font-size: 24px;
  font-weight: 800;
  line-height: 40px;
  margin-bottom: 18px;
  opacity: 0.8;
  @media screen and (max-width: 960px) {
    font-size: 18px;
    font-weight: 900;
    line-height: 36px;
    margin-bottom: 0;
  }
`;

export const ImgContainer = styled.div`
  padding: 20px;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 960px) {
    padding: 0;
  }
`;
export const Img = styled.img`
  width: 90%;
  height: 80%;
  object-fit: cover;
  border-radius: 18px;
  @media screen and (max-width: 960px) {
    width: 100%;
    height: 80%;
  }
`;

export const DownloadSectionContainer = styled.div`
  display: inline-flex;
  justify-content: space-between;
  padding: 0 30px;
  height: 200px;
  background: #f5f7f8;
  margin-top: 5%;
  border-radius: 18px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  align-items: center;
  width: 80%;
  margin-bottom: 30px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    margin-top: 12%;
    height: 200px;
    justify-content: space-evenly;
  }
`;

export const DownloadText = styled.span`
  color: #022c43;
  font-size: 32px;
  font-weight: 800;
  @media screen and (max-width: 960px) {
    display: flex;
    font-size: 24px;
    font-weight: 900;
    line-height: 36px;
    text-align: center;
    justify-content: center;
  }
`;

export const BtnContainer = styled.a`
  display: flex;
  width: 200px;
  height: 50px;
  background: #ff0000;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  text-decoration: none;
  &:hover,
  &:focus {
    background: #282828;
  }
  @media screen and (max-width: 960px) {
    width: 150px;
    height: 50px;
  }
`;

export const BtnText = styled.h3`
  color: #eee;
  font-size: 18px;
  @media screen and (max-width: 960px) {
    font-size: 14px;
  }
`;

// Features list styles //
export const FeaturesConatainer = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  li {
    padding-bottom: 16px;
    font-size: 26px;
    text-align: left;
    @media screen and (max-width: 960px) {
      font-size: 18px;
    }
    }
  }
`;
