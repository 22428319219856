import styled from "styled-components";
interface CoursesListContainerProps {
  isModalOpen: boolean;
}
export const CoursesListContainer = styled.div<CoursesListContainerProps>`
  display: flex;
  padding: 0px 30px;
  position: relative;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  transition: filter 0.3s ease;
${(props) =>
  props.isModalOpen &&
  `filter: blur(5px);`}   @media screen and (max-width: 960px) {
    padding: 2px 0px;
    height: 100%;
    margin-bottom: 20px;
  }
`;

export const CoursesBoxs = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 10px;
  padding: 0px 80px;
  flex-direction: row;
  @media screen and (max-width: 960px) {
    justify-content: center;
    margin-top: 20px;
    padding: 0px 25px;
  }
`;

export const Box = styled.div`
  width: calc(33.3% - 20px);
  height: 120%;
  margin-bottom: 20px;
  padding: 12px 12px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const ImgContainer = styled.div`
  width: 100%;
  height: 80%;
`;

export const Img = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 12px;
  @media screen and (max-width: 960px) {
    height: 80%;
  }
`;

export const HeadingTextContainer = styled.div`
  padding: 8px 12px;
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 960px) {
    height: 10%;
  }
`;

export const HeaderText = styled.span`
  color: #001524;
  font-size: 20px;
  font-weight: bold;
  opacity: 0.8;
  @media screen and (max-width: 960px) {
    font-size: 14px;
    font-weight: 900;
    margin-top: 10px;
    line-height: 40px;
    letter-spacing: 0.8px;
  }
`;

export const SubText = styled.span`
  color: #001524;
  font-size: 14px;
  font-weight: bold;
  opacity: 0.6;
  margin-top: 10px;
  letter-spacing: 0.9px;
  line-height: 20px;
  margin-bottom: 5px;
  @media screen and (max-width: 960px) {
    font-size: 12px;
    font-weight: 900;
    margin-top: 0;
    line-height: 20px;
    letter-spacing: 0.8px;
    margin-bottom: 0;
  }
`;

export const SubTextLanguage = styled.span`
  color: #000;
  font-size: 14px;
  font-weight: bold;
  opacity: 0.8;
  margin-top: 5px;
  letter-spacing: 0.9px;
  line-height: 20px;
  @media screen and (max-width: 960px) {
    font-size: 12px;
    font-weight: 900;
    margin-top: 0;
    line-height: 20px;
    letter-spacing: 0.8px;
    margin-bottom: 0;
  }
`;

export const Expirense = styled.span`
  color: #000;
  font-size: 14px;
  font-weight: bold;
  opacity: 0.8;
  margin-top: 5px;
  letter-spacing: 0.9px;
  line-height: 20px;
  @media screen and (max-width: 960px) {
    font-size: 12px;
    font-weight: 900;
    margin-top: 0;
    line-height: 20px;
    letter-spacing: 0.8px;
    margin-bottom: 0;
  }
`;

export const DateAndTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 12px;
  justify-content: space-between;
`;

export const HighLight = styled.span`
  color: #ef5a6f;
  font-size: 15px;
  font-weight: 900;
  margin-top: 5px;
  letter-spacing: 0.9px;
  line-height: 20px;
  @media screen and (max-width: 960px) {
    font-size: 12px;
    font-weight: 900;
    margin-top: 0;
    line-height: 40px;
    letter-spacing: 0.8px;
    margin-bottom: 0;
  }
`;

export const BtnContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BtnContainer = styled.a`
  display: flex;
  width: 140px;
  height: 40px;
  background-color: #0a0a3f;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-top: 23px;
  &:hover {
    background-color: #8f7b45;
  }
`;

export const BtnText = styled.span`
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  color: #fff;
`;

export const BtnContainerInfo = styled.div`
  display: flex;
  width: 140px;
  height: 40px;
  background-color: #8f7b45;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-top: 23px;
  &:hover {
    background-color: #16053a;
  }
`;

// modal //
export const Dialog = styled.dialog`
  padding: 1rem 3rem;
  background: #fff;
  max-width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  justify-content: center;
  padding-top: 2rem;
  border-radius: 20px;
  border: 0;
  box-shadow: 0 5px 30px 0 rgb(0 0 0 / 10%);
  animation: fadeIn 1s ease both;
  z-index: 10;
  &::backdrop {
    animation: fadeIn 1s ease both;
    background: rgb(255 255 255 / 40%);
    z-index: 2;
    backdrop-filter: blur(20px);
  }
  @media screen and (max-width: 960px) {
    padding: 1rem 2rem;
    width: 70%;
  }
`;


export const CloseButton = styled.button`
  filter: grayscale(1);
  border: none;
  background: none;
  position: absolute;
  top: 15px;
  right: 10px;
  transition: ease filter, transform 0.3s;
  cursor: pointer;
  transform-origin: center;
  &:hover {
    filter: grayscale(0);
    transform: scale(1.1);
  }
`;

export const HeaderBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderTextModal = styled.span`
  font-size: 18px;
  font-weight: bold;
`;
