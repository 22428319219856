import React from "react";
import { Nav, Footer } from "../../Compoents/Global";
// Compoents //
import { Hero, Offers, Sub, Details } from "../../Compoents/Nutrition";

const Nutrition = () => {
  return (
    <>
      <Nav />
      <Hero />
      <Offers />
      <Sub />
      <Details />
      <Footer />
    </>
  );
};

export default Nutrition;
