import ImgOne from "../imgs/img1.png";
import ImgTwo from "../imgs/img2.png";
import ImgThree from "../imgs/img3.png";
import ImgFour from "../imgs/img4.png";
import ImgFive from "../imgs/img5.png";
import ImgSix from "../imgs/img6.png";
import ImgSeven from "../imgs/img7.png";
import ImgEight from "../imgs/img8.png";
import ImgNine from "../imgs/img9.png";

export const DiscountList = [
  {
    id: 1,
    img: ImgOne,
    name: "3x Fitness-Session zum Geburtstag",
    description:
      "Feiere deinen Geburtstag mit meinem exklusiven Fitness-Paket! Ein unvergessliches Geburtstagsgeschenk für dich oder deine Liebsten.",
    cost: "199€",
    end: "1 Jahr",
    popUp: [
      "Erhalte drei individuelle Trainingseinheiten, die auf deine Bedürfnisse und Ziele abgestimmt sind. Egal, ob du deine Fitness steigern, Gewicht verlieren oder einfach Spaß haben möchtest. 3 Sessions konzentrieren wir uns auf die korrekte Ausführung von Übungen und einen Bodycheck.",
      "Du kannst den Gutschein innerhalb eines Jahres einlösen. ",
      "Mein Stützpunkt ist Crunch Fit Wedding in Berlin und die Sessions finden dort statt. Der Unterricht in anderen Fitnessstudios ist nach vorheriger Rücksprache ggf. möglich. ",
      "Wenn du nicht aus Berlin bist können wir die Sessions Online durchführen.",
    ],
    payment: "https://buy.stripe.com/00g5n1d6lgj5gX65kn",
  },
  {
    id: 2,
    img: ImgTwo,
    name: "3x Schwimm-Session zum Geburtstag",
    description:
      "Feiere deinen Geburtstag mit meinem einzigartigen Schwimm- Paket! Ein unvergessliches Geburtstagsgeschenk für dich oder deine Liebsten.",
    cost: "199€",
    end: "1 Jahr",
    popUp: [
      "Genieße drei individuell abgestimmte Schwimm-Sessions, perfekt für alle Leistungsniveaus. Egal, ob du deine Technik verbessern, deine Ausdauer steigern oder einfach Spaß im Wasser haben möchtest.",
      "Du kannst den Gutschein innerhalb eines Jahres einlösen. ",
      "Der Unterricht findet grundsätzlich im Kombibad Seestraße in Berlin statt. Der Unterricht in anderen Schwimmhallen ist nach vorheriger Rücksprache ggf. möglich. ",
      "in 3 Sessions eignest du dir die Grundkenntnisse einer Schwimmart an.",
    ],
    payment: "https://buy.stripe.com/7sI02Hfet6Iv9uE005",
  },
  {
    id: 3,
    img: ImgThree,
    name: "Paar-Geschenk 5x Fitness-Session",
    description:
      "Verschenke gemeinsame Fitnessmomente und bring frischen Wind in euer gemeinsames Fitnesstraining.",
    cost: "650€",
    end: "1 Jahr",
    popUp: [
      "Mein Stützpunkt ist Crunch Fit Wedding in Berlin und die Sessions finden dort statt. Der Unterricht in anderen Fitnessstudios ist nach vorheriger Rücksprache ggf. möglich.",
      "Du kannst den Gutschein innerhalb eines Jahres einlösen.",
      "Wenn du nicht aus Berlin bist können wir die Sessions Online durchführen.",
      "In 5 Sessions konzentrieren wir uns auf korrekte Ausführung von Übungen und einen Bodycheck.",
      "Du erhältst 5 kostenfreie Trainingspläne.",
    ],
    payment: "https://buy.stripe.com/cN24iXaYd8QDdKU8wC",
  },
  {
    id: 4,
    img: ImgFour,
    name: "Paar-Geschenk 5x Schwimmunterricht",
    description:
      "Verschenke unvergessliche Momente im Wasser und entdeckt die Freude am Schwimmen.",
    cost: "600€",
    end: "1 Jahr",
    popUp: [
      "Genieße fünf individuell abgestimmte Schwimm-Sessions, perfekt für alle Leistungsniveaus. Egal, ob ihr eure Technik verbessern, eure Ausdauer steigern oder einfach Spaß im Wasser haben möchtet.",
      "Du kannst den Gutschein innerhalb eines Jahres einlösen. Der Unterricht findet grundsätzlich im Kombibad Seestraße in Berlin statt. Der Unterricht in anderen Schwimmhallen ist nach vorheriger Rücksprache ggf. möglich.",
      "Innerhalb von 5 Sessions lernt ihr zuverlässig eine Schwimmart.",
    ],
    payment: "https://buy.stripe.com/fZe5n14zP8QDfT2bIP",
  },
  {
    id: 5,
    img: ImgFive,
    name: "3 Stunden Fitness Crashkurs",
    description:
      "Bring deine Fitness in nur drei intensiven Stunden auf das nächste Level. Der Kurs beinhaltet Korrekturen, Bewegungsanalysen, Selbstreflexion und das Erlernen neuer Übungen.",
    cost: "199€",
    end: "1 Jahr",
    popUp: [
      "Erhalte drei individuelle Trainingssessions, die auf deine Bedürfnisse und Ziele abgestimmt sind. -In drei Sessions lernst du die korrekte Ausführung aller wichtiger Übungen.",
      "Du kannst den Gutschein innerhalb eines Jahres einlösen.",
      "Mein Stützpunkt ist Crunch Fit Wedding in Berlin und die Sessions finden dort statt.",
      "Der Unterricht in anderen Fitnessstudios ist nach vorheriger Rücksprache ggf. möglich.",
      "Wenn du nicht aus Berlin bist können wir die Sessions Online durchführen.",
    ],
    payment: "https://buy.stripe.com/6oE7v94zP8QD0Y86ow",
  },
  {
    id: 6,
    img: ImgSix,
    name: "3 Stunden Schwimm-Crashkurs",
    description:
      "Bring deine Schwimmkenntnisse in nur drei intensiven Stunden auf das nächste Level. Nutze das Angebot um schnell und zuverlässig dein Schwimmniveau zu steigern.",
    cost: "199€",
    end: "1 Jahr",
    popUp: [
      "Genieße drei individuell abgestimmte Schwimm-Sessions, perfekt für alle Leistungsniveaus.",
      "Du kannst den Gutschein innerhalb von einem Jahr einlösen. Der Unterricht findet grundsätzlich im Kombibad Seestraße in Berlin statt. Der Unterricht in anderen Schwimmhallen ist nach vorheriger Rücksprache ggf. möglich. In drei Sessions kannst du deine Technik in verschiedenen Schwimmstilen verbessern.",
    ],
    payment: "https://buy.stripe.com/3cs8zdd6lff17mwdQZ",
  },
  {
    id: 7,
    img: ImgSeven,
    name: "2 Stunde Crashkurs Kraulschwimmen lernen",
    description:
      "Lerne in nur zwei Stunden zu Kraulen oder verbessere deine Kraultechnik.Kraulschwimmen lernen war noch nie so einfach! Und das in nur zwei Stunden. ",
    cost: "150€",
    end: "1 Jahr",
    popUp: [
      "In nur zwei Stunden kannst du die Grundlagen des Kraulschwimmens lernen oder deine Schwimmtechnik verbessern.",
      "Du kannst den Gutschein innerhalb von einem Jahr einlösen. Der Unterricht findet grundsätzlich im Kombibad Seestraße in Berlin statt. Der Unterricht in anderen Schwimmhallen ist nach vorheriger Rücksprache ggf. möglich.",
    ],
    payment: "https://buy.stripe.com/dR6bLp1nDd6T7mwfZ8",
  },
  {
    id: 8,
    img: ImgEight,
    name: "1 Monat Schwimmtrainingsplan für Wettkämpfe",
    description:
      "Training nach Plan für Wettkämpfe wie Triathlon oder Freiwasser-Schwimmen.",
    cost: "150€",
    end: "1 Jahr",
    popUp: [
      "In 30 Tagen bekommst du insgesamt 12 unterschiedliche Trainingspläne.",
      "Du kannst den Gutschein innerhalb von einem Jahr einlösen.",
      "4 mal 15 min Check-In per Video-Call um deinen Fortschritt zu besprechen.",
      "Der Unterricht findet grundsätzlich im Kombibad Seestraße in Berlin statt. Der Unterricht in anderen Schwimmhallen ist nach vorheriger Rücksprache ggf. möglich. ",
    ],
    payment: "https://buy.stripe.com/6oEdTxaYd7Mz36g5kv",
  },
  {
    id: 9,
    img: ImgNine,
    name: "1 Stunde Tipps und Tricks für’s Schwimmen",
    description:
      "Training nach Plan für Wettkämpfe wie Triathlon oder Freiwasser-Schwimmen.",
    cost: "60€",
    end: "1 Jahr",
    popUp: [
      "In dieser Stunde lernest du wie du effektiver schwimmst.",
      "Wir beheben Fehler für mehr Spaß beim Schwimmen.",
      "Du kannst den Gutschein innerhalb von einem Jahr einlösen.",
      "Der Unterricht findet grundsätzlich im Kombibad Seestraße in Berlin statt. Der Unterricht in anderen Schwimmhallen ist nach vorheriger Rücksprache ggf. möglich.",
    ],
    payment: "https://buy.stripe.com/cN2aHl3vL5ErfT228k",
  },
];
