import React from "react";
// Components
import {
  MainContainer,
  MainSubContainer,
  Card,
  Avatar,
  Name,
  Bio,
  FollowButton,
} from "./styles";
// profile //
import ProfileImg from "../../../assets/imgs/profileImg.png";
const Profiles = () => {
  return (
    <MainContainer>
      <MainSubContainer>
        <Card>
          <Avatar src={ProfileImg} alt="Profile Picture" />
          <Name>Pouya Pourebrahim</Name>
          <Bio>Fitness-Trainer und Schwimmcoach</Bio>
          <FollowButton href="https://calendly.com/fitness-joinpuya">
            Book me
          </FollowButton>
        </Card>
        {/* <Card>
          <Avatar src="https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDV8fHxlbnwwfHx8fHw%3D" alt="Profile Picture" />
          <Name>John Smith</Name>
          <Bio>Hi i am Jane Doe i am a fitness programmer</Bio>
          <FollowButton>Book me</FollowButton>
        </Card>
        <Card>
          <Avatar src="https://images.unsplash.com/photo-1580489944761-15a19d654956?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDd8fHxlbnwwfHx8fHw%3D" alt="Profile Picture" />
          <Name>Emily Johnson</Name>
          <Bio>Hi i am Jane Doe i am a fitness programmer</Bio>
          <FollowButton>Book me</FollowButton>
        </Card>
        <Card>
          <Avatar src="https://images.unsplash.com/photo-1474176857210-7287d38d27c6?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE0fHx8ZW58MHx8fHx8" alt="Profile Picture" />
          <Name>Emily Johnson</Name>
          <Bio>Hi i am Jane Doe i am a fitness programmer</Bio>
          <FollowButton>Book me</FollowButton>
        </Card>
        <Card>
          <Avatar src="https://images.unsplash.com/photo-1450133064473-71024230f91b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDV8fHxlbnwwfHx8fHw%3D" alt="Profile Picture" />
          <Name>Emily Johnson</Name>
          <Bio>Hi i am Jane Doe i am a fitness programmer</Bio>
          <FollowButton>Book me</FollowButton>
        </Card>
        <Card>
          <Avatar src="https://images.unsplash.com/photo-1541236532439-808ff5b998dd?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE5fHx8ZW58MHx8fHx8" alt="Profile Picture" />
          <Name>Emily Johnson</Name>
          <Bio>Hi i am Jane Doe i am a fitness programmer</Bio>
          <FollowButton>Book me</FollowButton>
        </Card> */}
      </MainSubContainer>
    </MainContainer>
  );
};

export default Profiles;
